body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 100;
    background: #111;
    color: white;
    margin: 0;
}

.App {
    height: 100%;
}