.DesktopMode {
    --widthA: calc(var(--heightA) /2);
    --heightA: 90vh;

    max-width: var(--widthA);
    max-height: var(--heightA);
    
    --widthB: calc(100vw - var(--widthA) );
    --widthC: calc(var(--widthB) / 2);
    
    --heightB: calc(100vh - var(--heightA) );
    --heightC: calc(var(--heightB) / 2);
    margin: var(--heightC) var(--widthC);
}